import {defineStore} from 'pinia'
import {ErrorType} from '@/models/ErrorType';
import SnackBarMessage from '@/models/SnackBarMessage';
import {ApolloGraphQlError, GraphQlErrorUtil, GraphQlResponse, GraphQlResult} from "@/models/GraphQlError";
import {SnackBarMessageType} from "@/models/SnackBarMessageType";
import {AuthenticationProvider, GetLayoutInfoQuery} from "@/graphql/generated/internal/types";

export default defineStore('general', {
    state: () => {
        return {
            errorState: undefined as ErrorType | undefined,
            pageLoading: 0 as number,
            snackStack: [] as SnackBarMessage[],
            username: "dummyuser" as string,
            version: "v0.0.0.0" as string,
            siteName: "dev" as string,
            actor: {
                username: "dummyuser",
                timezone: "GMT",
                domain: {
                    code: "pt",
                    authenticationProvider: AuthenticationProvider.Ad,
                    authenticationProviderAttributes: {
                        externallyGoverned: false
                    }
                },
                company: {
                    name: "Dummy Company",
                    key: "dummycompany"
                }
            } as GetLayoutInfoQuery["whoAmI"],
            userGuide: "" as string,
            permissions: [] as string[]
        }
    },

    actions: {
        /**
         *
         * @param snack Snackbar message to be displayed.
         * @param queued Previous snacks will be kept in history/queue.
         */
        addSnack(snack: SnackBarMessage, queued: boolean) {
            if (queued) {
                const index = this.snackStack.findIndex(existingSnack => existingSnack.flowId === snack.flowId);
                if (index !== -1) {
                    this.snackStack[index] = snack;
                } else {
                    this.snackStack.push(snack);
                }
            } else {
                this.snackStack = [snack];
            }
        },

        addErrorSnack(wrapper: GraphQlResult | GraphQlResponse | ApolloGraphQlError, message?: string) {
            if (message != undefined) {
                message = message.trim();
                if (![".", "!"].some(c => message && message.length > 0 && message.endsWith(c))) {
                    message += "."
                }
            } else {
                message = "";
            }

            const response = "response" in wrapper ? wrapper.response : wrapper;
            const snacks = GraphQlErrorUtil.getMessage(response, message);
            for (const snack of snacks) {
                this.addSnack(snack, true);
            }
        },


        addInfoSnack(message: string) {
            this.addSnack({
                type: SnackBarMessageType.MESSAGE,
                message: message
            }, true);
        },

        addSuccessSnack(message: string) {
            this.addSnack({
                type: SnackBarMessageType.SUCCESS,
                message: message
            }, true);
        },

        addSimpleExceptionSnack(message: string) {
            this.addSnack({
                type: SnackBarMessageType.ERROR,
                message: message
            }, true);
        },

        getTimezone() {
            return this.actor.timezone ?? "GMT";
        },

        setTimezone(timezone: string) {
            this.actor.timezone = timezone;
        },

        addLoading() {
            this.pageLoading++;
        },

        removeLoading() {
            if (this.pageLoading > 0) {
                this.pageLoading--
            } else {
                console.warn("Page loading counter is already at 0.")
            }
        },
    }
})