





























import Vue from "vue";
import {copyParams} from "@/util/Url";

export default Vue.extend({
  props: {
    idp: {
      required: true,
      type: String
    },
    idpUrl: {
      required: true,
      type: String
    }
  },
  data: () => ({
    remember: false,
    redirectLoading: false,
    declineLoading: false
  }),
  methods: {
    redirect: function (): void {
      this.redirectLoading = true;
      let redirectUrl = this.idpUrl;
      if (this.remember) {
        redirectUrl = redirectUrl + (redirectUrl.indexOf("?") >= 0 ? "&" : "?") + "ari=1";
      }
      window.location.assign(redirectUrl);
    },
    decline: function (): void {
      this.declineLoading = true;
      if (this.remember) {
        let copied = copyParams("login");
        window.location.assign(copied + (copied.indexOf("?") >= 0 ? "&" : "?") + "dnri=1");
      } else {
        this.$emit("decline");
      }
    }
  }
});
