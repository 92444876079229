class HttpError extends Error {
    public readonly response: Response;
    
	constructor(message: string, response: Response) {
		super(message);
		Object.setPrototypeOf(this, HttpError.prototype);
		this.response = response;
	}
}

export default HttpError;
