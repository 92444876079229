export default class {
	static clientInfo(): string {
		
		const browser = navigator.userAgent.match(/(opera|chrome|safari|firefox|msie|trident)\/?\s*(\.?\d+(\.\d+)*)/i);
		
		const profile = {
			platform: navigator.platform,
			language: navigator.language,
			userAgent: navigator.userAgent,
			windowWidth: 0,
			windowHeight: 0,
			screenWidth: 0,
			screenHeight: 0,
			javaEnabled: navigator.javaEnabled(),
			browser: browser ? browser[1] : "other"
		};
		
		try {
			profile.windowWidth = window.innerWidth || (document.documentElement.clientWidth || document.body.clientWidth);
			profile.windowHeight = window.innerHeight || (document.documentElement.clientHeight || document.body.clientHeight);
			profile.screenWidth = window.screen.width;
			profile.screenHeight = window.screen.height;
		} catch (err) {
			if (console && console.error) {
				console.error(err);
			}
		}
		
		return JSON.stringify(profile);
	}
}
