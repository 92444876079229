export const plainUrl = function (url: string): URL {
	return new URL(process.env.VUE_APP_WEB_CONTEXT + url, window.location.origin);
}

export const copyParams = function (url: string): string {
	const current = new URL(window.location.href, window.location.origin);
	
	const withParams = plainUrl(url);
	
	const method = current.searchParams.get("method");
	if (method) {
		withParams.searchParams.set("method", method);
	}
	
	const format = current.searchParams.get("format");
	if (format) {
		withParams.searchParams.set("format", format);
	}
	
	const protocol = current.searchParams.get("protocol");
	if (protocol) {
		withParams.searchParams.set("protocol", protocol);
	}
	
	const service = current.searchParams.get("service");
	if (service) {
		withParams.searchParams.set("service", service);
	}
	
	return withParams.href;
}

export const getService = function (): string | null {
	const current = new URL(window.location.href, window.location.origin);
	return current.searchParams.get("service");
}

export const addService = function (url: string, service: string | null): string {
	const withService = plainUrl(url);
	if (service) {
		withService.searchParams.set("service", service);
	}
	return withService.href;
}
