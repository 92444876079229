




































import Vue from "vue";
import PrivacyNotice from "@/components/PrivacyNotice.vue";
export default Vue.extend({
  components: {
    PrivacyNotice
  },
  props: {
    hide: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  data: () => ({
    isCentral: process.env.VUE_APP_PACK === "central"
  }),
  created() {
    let preference = localStorage.getItem("darkModeEnabled");
    if (preference === "true") {
      this.$vuetify.theme.dark = true;
    }
    if (document.getElementById("serverdata") && document.getElementById("serverdata")!.textContent! !== "$data") {
      const siteName = JSON.parse(document.getElementById("serverdata")!.textContent!).siteName;
      if (siteName) {
        document.title = `OneLogin - ${siteName}`;
      }
    }

  }
});
