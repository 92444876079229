






















import Vue from "vue";

export default Vue.extend({
  props: {
    responseParams: Object,
    service: String
  },
  data: () => ({}),
  computed: {
    wrapped: function (): boolean {
      return window.self !== window.top;
    }
  },
  mounted() {
    if (this.wrapped) {
      this.$emit("hide-layout");
    }
    ((this.$refs.form as Vue).$el as HTMLFormElement).submit();
  }
});
