
























import useGeneralStore from "@/stores/general"
import {SnackBarMessageType} from "@/models/SnackBarMessageType";
import {computed, ref, toRefs, watch} from '@vue/composition-api'
import Vue from "vue";

export default Vue.extend({
  name: "QueuedSnackbar",
  setup() {

    const store = useGeneralStore();
    const {snackStack} = toRefs(store);
    const showSnackbar = ref(true);
    let timeoutId = window.setTimeout(() => {
      snackStack.value.pop();
    }, 5000);


    const message = computed(() => {
      if (snackStack.value.length >= 1) {
        return snackStack.value[snackStack.value.length - 1].message;
      }
      return "";
    })


    const flowId = computed(() => {
      if (snackStack.value.length >= 1) {
        return snackStack.value[snackStack.value.length - 1].flowId;
      }
      return "";
    })

    watch(snackStack, () => {
      if (timeoutId >= 0) {
        clearTimeout(timeoutId);
      }
      timeoutId = window.setTimeout(() => {
        snackStack.value.pop();
      }, 5000);
    })

    function determineColor(type: SnackBarMessageType) {

      switch (type) {
        case SnackBarMessageType.SUCCESS:
          return "success";
        case SnackBarMessageType.MESSAGE:
          return "blue-grey darken-3";
        case SnackBarMessageType.WARNING:
          return "warning";
        case SnackBarMessageType.ERROR:
          return "error";
        default:
          return "black";
      }
    }

    function determineIcon(type: SnackBarMessageType) {
      switch (type) {
        case SnackBarMessageType.SUCCESS:
          return "check_circle";
        case SnackBarMessageType.WARNING:
          return "warning";
        case SnackBarMessageType.ERROR:
          return "error";
        case SnackBarMessageType.MESSAGE:
          return "info";
        default:
          return "info";
      }
    }

    const color = computed(() => {
      if (snackStack.value.length >= 1) {
        return determineColor(snackStack.value[snackStack.value.length - 1].type);
      }
      return "";
    })


    const icon = computed(() => {
      if (snackStack.value.length >= 1) {
        return determineIcon(snackStack.value[snackStack.value.length - 1].type);
      }
      return "";
    })

    return {
      showSnackbar,
      snackStack,
      icon,
      color,
      message,
      flowId
    };

  },


});
