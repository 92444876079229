











import Vue from "vue";

export default Vue.extend({
  props: {
    error: {
      type: String,
      required: true
    },
    showContinue: {
      type: Boolean,
      default: true
    }
  },
  data: () => ({})
});
