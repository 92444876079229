export enum LoginResponse {
	SUCCESS = "SUCCESS",
	SUCCESS_WITH_WARNINGS = "SUCCESS_WITH_WARNINGS",
	WARN = "WARN",
	AUTHENTICATION_FAILURE = "AUTHENTICATION_FAILURE",
	ERROR = "ERROR",
	POLICY_CONSENT_REQUIRED = "POLICY_CONSENT_REQUIRED",
	TWO_FACTOR_REQUIRED = "NEEDS_TWO_FACTOR",
	PASSWORD_EXPIRED = "PASSWORD_EXPIRED",
	PASSWORD_MUST_CHANGE = "PASSWORD_MUST_CHANGE",
	AD_TRANSITION = "AD_TRANSITION",
    NOTIFICATION_REQUIRED = "NOTIFICATION_REQUIRED",
	MARKETING_CONSENT_REQUIRED = "MARKETING_CONSENT_REQUIRED"
}
