





















import Vue from "vue";
import Fetch from "@/util/Fetch";

const api = process.env.VUE_APP_API_PATH || "";
const context = process.env.VUE_APP_WEB_CONTEXT || "";

export default Vue.extend({
  data: () => ({
    error: "",
    creating: false
  }),
  methods: {
    create: function (): void {
      this.creating = true;
      this.error = "";
      Fetch.postForm(api + context + "imsapi/createNewUser", {})
          .then(() => {
            this.$emit("created");
          })
          .catch(e => {
            if (console && console.error) {
              console.error(e);
            }
            Fetch.apiErrorMessage(e).then(message => this.error = message);
          })
          .finally(() => {
            this.creating = false;
          });
    }
  }
});
