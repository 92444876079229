<template>
  <div class="privacy">
    <a class="text-decoration-none" href="privacynotice"  target="_blank">Privacy Notice</a>  <!--Should it be a modal? -->
  </div>

</template>

<script>
export default {
  name: 'PrivacyNotice',
};
</script>

<style scoped>
.privacy {
position:absolute;
  text-align: center;
  bottom:1rem;
}
</style>