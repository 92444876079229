












































































































































































































import Vue from "vue";
import Fetch from "@/util/Fetch";

const api = process.env.VUE_APP_API_PATH || "";
const context = process.env.VUE_APP_WEB_CONTEXT || "";

export default Vue.extend({
  data: () => ({
    step: 0,
    showAndroidQr: false,
    showIosQr: false,
    done: false,
    secret: "",
    secretImage: "",
    secretError: false,
    secretLoaded: false,
    token: "",
    tokenLoading: false,
    tokenError: false,
    validationRules: [
      (value: string) => !!value || "Required",
      (value: string) => value && value.toString().length === 6 || "Must be 6 digits"
    ],
    rules: [] as { (value: string): boolean | string; }[]
  }),
  methods: {
    reset: function (): void {
      this.secretError = false;
      this.tokenError = false;
      this.done = false;
    },
    back: function (): void {
      this.reset();
      this.step--;
    },
    forward: function (): void {
      this.reset();
      this.step++;
    },
    barcodeStep: function (): void {
      this.step = 2;
      this.secretError = false;
      if (!this.secretLoaded) {
        Fetch.get(api + context + "imsapi/twofactorsetup/secret")
            .then(json => {
              this.secretImage = json.imageUrl;
              this.secret = json.secret;
              this.secretLoaded = true;
            })
            .catch(e => {
              if (console && console.error) {
                console.error(e);
              }
              this.secretError = true;
            });
      }
    },
    attemptActivate: function (): void {
      if (this.token.length === 6) {
        this.activate();
      }
    },
    activate: function (): void {

      this.validate().then(valid => {

        if (!valid) return;

        this.disableRules();

        this.tokenLoading = true;
        Fetch.postForm(api + context + "imsapi/twofactorsetup/activate", {
          "token": this.token
        })
            .then(() => {
              this.done = true;
              this.step = 4;
            })
            .catch(e => {
              if (console && console.error) {
                console.error(e);
              }
              this.tokenError = true;
            })
            .finally(() => {
              this.tokenLoading = false;
            });
      });
    },
    validate: function (): Promise<boolean> {
      this.enableRules();
      return Vue.nextTick().then(() => (this.$refs.form as Vue & { validate: () => boolean }).validate());
    },
    enableRules: function (): void {
      this.rules = this.validationRules;
    },
    disableRules: function (): void {
      this.rules = [];
    }
  }
});
