import {SnackBarMessageType} from "@/models/SnackBarMessageType";
import SnackBarMessage from "./SnackBarMessage";

export interface ApolloGraphQlError {
    graphQLErrors: GraphQlError[];
    message: string;
    networkError: any;
}


export interface GraphQlResult {
    request: any;
    response: GraphQlResponse;
}

export interface GraphQlResponse {
    errors: GraphQlError[];
    meta: any;
}

export default interface GraphQlError {
    message: string;
    extensions: GraphQlErrorExtensions,
    locations: GraphQlErrorLocation,
    path: string[]
}

export interface GraphQlErrorLocation {
    column: number,
    line: number,
    sourceName: string | null
}

export interface GraphQlErrorExtensions {
    flow: string,
    type: string
}




export class GraphQlErrorUtil {
    static getMessage = (response: GraphQlResponse | ApolloGraphQlError, comment: string): SnackBarMessage[] => {
        if (!response) {
            return [];
        }

        let errors: GraphQlError[] = [];
        if ("graphQLErrors" in response) {
            errors = response.graphQLErrors;
        } else if ("errors" in response) {
            errors = response.errors;
        }


        const snackErrors: SnackBarMessage[] = [];
        for (const error of errors) {
            let message = error.message;
            message = `${comment} ${this.extractMessage(error.message).message}`
            snackErrors.push({message: message, type: SnackBarMessageType.ERROR, flowId: error.extensions.flow});
        }
        return snackErrors;
    };

    static extractMessage(fullErrorText: string): {message: string, flowId: string} {
        const message = fullErrorText;

        const regex = /(GraphQL error: )?Exception while fetching data \(.*?\) : (.*?) \(exception id: (.*?)\).*/gm;
        const r = regex.exec(fullErrorText);
        if (r != null && r.length > 1) {
            return {
                message: r[2],
                flowId: r[3]
            }
        }

        return {message: message, flowId: ""};
    }


}