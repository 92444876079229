import Fetch from "@/util/Fetch";

const url = `${process.env.VUE_APP_API_PATH}${process.env.VUE_APP_WEB_CONTEXT}graphql`
const url_public = `${process.env.VUE_APP_API_PATH}${process.env.VUE_APP_WEB_CONTEXT}graphql_public`

let actor = "";

export default class GraphQl {

    static query(query:string, path:string, variables:any={}): Promise<any>{
        return Fetch.postForm(url, {"query": query, "variables": JSON.stringify(variables)},{timeout: 300_000})
        .then(response=> response.json())
        .then(data => {
            if (data.errors) {
                throw data.errors[0];
            } else {
                actor = data.meta.actor;
                return data.data[path];
            }
        })
    }

    static queryPublic(query:string, path:string): Promise<any>{
        return Fetch.postForm(url_public, {"query": query})
        .then(response=> response.json())
        .then(data => {
            if (data.errors) {
                throw new Error(data.errors[0]);
            } else {
                return data.data[path];
            }
        }).catch(error => {
           throw new Error(error.message);
        }); 
    }

    static queryInner(query:string,path1:string,path2:string): Promise<any>{
        return Fetch.postForm(url, {"query": query},{timeout: 300_000})
        .then(response=> response.json())
        .then(data => {
            if (data.errors) {
                throw data.errors[0];
            } else {
                return data.data[path1][path2];
            }
        })
    }

    static mutate(mutation:string, path:string, variables:any={}): Promise<any>{
        return Fetch.postForm(url, {"query": mutation, "variables": JSON.stringify(variables)},{timeout: 300_000})
        .then(response=> response.json())
        .then(data => {
            if (data.errors) {
                throw data.errors[0];
            } else {
                return data.data[path];
            }
        })
    }

    static getActor(): string{
        return actor;
    }

}