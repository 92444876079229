import 'core-js/stable'
import "regenerator-runtime/runtime"
import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import '@mdi/font/css/materialdesignicons.css'
import { preset } from '@playtech/vue-cli-plugin-design-system-vuetify-preset/preset'

Vue.use(Vuetify)

const lightTheme = preset.theme.themes.light;

export default new Vuetify({
  preset,
  rtl: false,
  theme: {
    dark: false,
    themes: {
      dark: {
        primary: lightTheme.primary,
        secondary: lightTheme.secondary,
        accent: lightTheme.accent,
        error: lightTheme.error,
        info: lightTheme.info,
        success: lightTheme.success,
        warning: lightTheme.warning,
      },
    },
    options: {
      customProperties: true
    }
  },
  icons: {
    iconfont: 'md'
  }
})
