import {GraphQLClient} from "graphql-request";
import {getSdk} from "@/graphql/generated/internal/types";
import Fetch from "@/util/Fetch";

const graphQLClient = new GraphQLClient(`${process.env.VUE_APP_API_PATH}${process.env.VUE_APP_WEB_CONTEXT}graphql`, {
    credentials: 'include',
    mode: 'cors',
    redirect: 'follow',
    fetch: Fetch.postGraphQlForm
});

const Connector = getSdk(graphQLClient);
export default Connector;
