






































import Vue from "vue";
import Fetch from "@/util/Fetch";

const api = process.env.VUE_APP_API_PATH || "";
const context = process.env.VUE_APP_WEB_CONTEXT || "";

export default Vue.extend({
  props: {
    daysRemaining: Number
  },
  data: () => ({
    noLoading: false,
    yesLoading: false,
    error: false
  }),
  methods: {
    decline: function (): void {
      this.error = false;
      this.noLoading = true;
      Fetch.postForm(api + context + "imsapi/adtransition/decline", {})
          .then(() => {
            this.$emit("decline");
          })
          .catch(e => {
            if (console && console.error) {
              console.error(e);
            }
            this.error = true;
          })
          .finally(() => {
            this.noLoading = false;
          });
    },
    approve: function (): void {
      this.error = false;
      this.yesLoading = true;
      Fetch.postForm(api + context + "imsapi/adtransition/approve", {})
          .then(() => {
            this.$emit("approve");
          })
          .catch(e => {
            if (console && console.error) {
              console.error(e);
            }
            this.error = true;
          })
          .finally(() => {
            this.yesLoading = false;
          });
    }
  }
});
