












import Vue from "vue";
import {copyParams} from "@/util/Url";

export default Vue.extend({
  methods: {
    url: function (url: string): string {
      return copyParams(url);
    }
  }
});
