import HttpError from "@/models/HttpError";

const DEFAULT_TIMEOUT = 300_000;

export const defaultFetchOptions = {
	mode: "cors" as RequestMode,
	credentials: "include" as RequestCredentials,
	redirect: "follow" as RequestRedirect
}

export const DEFAULT_MESSAGE = "Unable to contact OneLogin. Please try again.";

export default class Fetch {


	static postGraphQlForm(url: string, data: { body: string }): Promise<Response> {
		const fetchData = JSON.parse(data.body);
		if (fetchData.variables) {
			fetchData.variables = JSON.stringify(fetchData.variables)
		}

		return Fetch.postForm(url, fetchData);
	}

	static postForm(url: string, data: { [key: string]: string }, opts?: RequestInit & {timeout?: number} ): Promise<Response> {
		const formData = new URLSearchParams();
		Object.keys(data).forEach(function (key: string) {
			formData.append(key, data[key]);
		});

		const controller = new AbortController();
		const timeoutId = setTimeout(() => controller.abort(), (opts != undefined && opts.timeout ? opts.timeout : DEFAULT_TIMEOUT));

		const fetchOpts = Object.assign({}, defaultFetchOptions, {signal: controller.signal}, opts, {
			"method": "POST",
			"headers": {
				"Content-Type": "application/x-www-form-urlencoded"
			},
			"body": formData.toString()
		});
		return fetch(url, fetchOpts)
			.then(response => this.handleErrors(response))
			.then(response => {
				clearTimeout(timeoutId);
				return response;
			});
	}

    static postJson(url: string, data: { [key: string]: string }, opts?: RequestInit): Promise<any> {
        const controller = new AbortController();
        const timeoutId = setTimeout(() => controller.abort(), DEFAULT_TIMEOUT);

        const fetchOpts = Object.assign({}, defaultFetchOptions, {signal: controller.signal}, opts, {
            "method": "POST",
            "headers": {
                "Accept": "application/json, text/plain, */*",
                "Content-Type": "application/json;charset=UTF-8"
            },
            "body": JSON.stringify(data)
        });
        return fetch(url, fetchOpts)
            .then(response => this.handleErrors(response))
            .then(response => response.json())
            .then(response => {
                clearTimeout(timeoutId);
                return response;
            });
    }


	static get(url: string, opts?: RequestInit): Promise<any> {

		const controller = new AbortController();
		const timeoutId = setTimeout(() => controller.abort(), DEFAULT_TIMEOUT);

		const fetchOpts = Object.assign({}, defaultFetchOptions, {signal: controller.signal}, opts) as RequestInit;
		return fetch(url, fetchOpts)
			.then(response => this.handleErrors(response))
            .then(response => response.json())
			.then(json => {
				clearTimeout(timeoutId);
				return json;
			});
	}

    static delete(url: string, data: { [key: string]: string }, opts?: RequestInit): Promise<any> {

        const controller = new AbortController();
        const timeoutId = setTimeout(() => controller.abort(), DEFAULT_TIMEOUT);

        const fetchOpts = Object.assign({}, defaultFetchOptions, {signal: controller.signal}, opts, {
            "method": "DELETE",
            "headers": {
                "Accept": "application/json, text/plain, */*",
                "Content-Type": "application/json;charset=UTF-8"
            },
            "body": JSON.stringify(data)
        }) as RequestInit;
        return fetch(url, fetchOpts)
            .then(response => this.handleErrors(response))
            .then(response => response.json())
            .then(text => {
                clearTimeout(timeoutId);
                return text;
            });
    }

	static handleErrors(response: Response): Response {
		if (!response.ok) {
			const message = response.statusText != undefined ? response.statusText : "request failed";
			throw new HttpError(message, response);
		}
		return response;
	}

	static apiErrorMessage(error: Error): Promise<string> {
		if (error instanceof HttpError) {
			return error.response.json()
				.then(json => json.error.message)
				.catch(() => Promise.resolve(DEFAULT_MESSAGE));
		} else {
			return Promise.resolve(DEFAULT_MESSAGE);
		}
	}
}
