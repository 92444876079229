





































import Vue from "vue";
import Fetch from "@/util/Fetch";
import {Step, stepFromResult} from "@/models/Step";
import TwoFactorSetup from "@/components/frontend/login/TwoFactorSetup.vue";

const api = process.env.VUE_APP_API_PATH || "";
const context = process.env.VUE_APP_WEB_CONTEXT || "";

export default Vue.extend({
  components: {TwoFactorSetup},
  props: {
    lt: String
  },
  data: () => ({
    statusFetched: false,
    statusError: "",
    token: "",
    error: false,
    loading: false,
    enabled: false,
    activated: false,
    validationRules: [
      (value: string) => !!value || "Required",
      (value: string) => value && value.toString().length === 6 || "Must be 6 digits"
    ],
    rules: [] as { (value: string): boolean | string; }[]
  }),
  computed: {
    needsEnabling(): boolean {
        return this.statusFetched && !this.enabled;
    },
    needsActivation(): boolean {
      return this.statusFetched && this.enabled && !this.activated;
    }
  },
  created(): void {
    this.fetchStatus();
  },
  mounted(): void {
    this.loading = false;
  },
  methods: {
    submit: function(): void {
      this.validate().then(valid => {
        if (valid) {
          this.disableRules();
          this.authenticate();
        }
      });
    },
    attemptAuthenticate: function (): void {
      if (this.token.length === 6) {
        this.authenticate();
      }
    },
    authenticateAfterSetup: function (token:string): void {
      this.token = token;
      this.authenticate();
    },
    authenticate: function (): void {
      if (this.loading) return;

      this.loading = true;
      Fetch.postForm(api + context + "login/twoFactor", {
        "token": this.token,
        "lt": this.lt
      })
          .then(response => response.json())
          .then(json => {
            const step = stepFromResult(json.result);
            if (step === Step.ERROR) {
              this.error = true;
              this.loading = false;
            } else {
              this.$emit("update", json);
            }
          })
          .catch(e => {
            if (console && console.error) {
              console.error(e);
            }
            this.error = true;
            this.loading = false;
          });
    },
    fetchStatus: function (): void {
      this.statusFetched = false;
      Fetch.get(api + context  + "imsapi/twofactorsetup/status")
          .then(json => {
            this.enabled = json.enabled;
            this.activated = json.activated;
          })
          .catch(e => {
            if (console && console.error) {
              console.error(e);
            }
            Fetch.apiErrorMessage(e).then(message => this.statusError = message);
          })
          .finally(() => {
            this.statusFetched = true;
          });
    },
    validate: function (): Promise<boolean> {
      this.enableRules();
      return Vue.nextTick().then(() => (this.$refs.form as Vue & { validate: () => boolean }).validate());
    },
    enableRules: function (): void {
      this.rules = this.validationRules;
    },
    disableRules: function (): void {
      this.rules = [];
    }
  }
});
