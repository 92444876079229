









import Vue from "vue";

export default Vue.extend({
  props: {
    warnings: {
      type: Array,
      required: true
    },
    redirectTime: {
      type: Number,
      required: true
    }
  },
  data: () => ({
    idx:0,
    timeoutHandle: 0,
    loading: false
  }),
  mounted() {
    if (this.redirectTime > 0) {
      this.timeoutHandle = window.setTimeout(() => {
        this.next();
      }, this.redirectTime * 1000)
    }
    this.loading = false;
  },
  methods: {
    next: function (): void {
      if (this.idx < this.$props.warnings.length-1) {
        this.idx += 1
        if (this.timeoutHandle) {
          window.clearTimeout(this.timeoutHandle);
        }
        this.timeoutHandle = window.setTimeout(() => {
          this.next();
        }, this.redirectTime * 1000)
        return
      }
      this.loading = true;
      if (this.timeoutHandle) {
        window.clearTimeout(this.timeoutHandle);
      }
      this.$emit("continue");
    }
  }
});
